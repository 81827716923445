import styled, { css, device } from "@styled";

export const IntroWrapper = styled.section`
  padding: 60px 0 100px;
  ${device.medium} {
    padding: 100px 0 100px;
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background: #fff;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background: #f8f8f8;
    `}
      ${(props) =>
    props.layout === 3 &&
    css`
    background: #fff;
    padding-top:20px !important;
  `}
  ${(props) =>
    props.layout === 4 &&
    css`
  background: #fff;
  padding-bottom:0px !important;
`}
`;

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 20px;
  }
`;
export const RightBox = styled.div`
display:flex;
justify-content:end;
  margin: 15px 0;
  ${device.large} {
    margin: 0;
  }
`;
export const ButtonWrap = styled.div`
  margin-top: 30px;
  a {
    &:first-child {
      margin-left: 0;
    }
  }
`;
export const ContentWarp = styled.div`
  margin-top: 40px;
  h5 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  p {
    font-size: 17px;
  }
`;
