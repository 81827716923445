import React, { Suspense, lazy, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
// import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import IntroAreaReverseOne from "@components/introareareverse/layout-4";
import IntroAreaReverse from "@components/introareareverse/layout-5";
import IntroAreaReverseTwo from "@components/introareareverse/layout-6";
import IntroAreaOne from "@components/introarea/layout-4";
import BoxSection from "@components/BoxSection/layout-one/layout-2";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import UseScroll from '@containers/scroll';
import QuoteForm from "@containers/translation-quote-form";

const Footer =lazy(()=>import("@layout/footer/layout-01"))
const MarketResearchTranslationServicesPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const content = normalizedData(data?.page?.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 100) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Market Research Translation Services"
        description=""
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />

      <main className="site-wrapper-reveal">
        <PageHeader data={content["market-translation-page-header-section"]} />
        <QuoteForm />
        <FeatureGrid data={content["market-translation-features"]} />
        {
          isMobile && showRestComponents ?<>
         <IntroAreaOne layout={2} data={content["market-intro-body"]} /> 
          </> 
          : !isMobile &&<>
         <IntroAreaOne layout={2} data={content["market-intro-body"]} />
          </>
        }
        <IntroAreaReverseOne layout={2} data={content["market-solutions-body"]} />
        {
          showRestComponents && <>
            <IntroAreaReverseTwo layout={1} data={content["translation-quality-smes"]} />
            <IntroAreaReverse layout={2} data={content["market-coverage-body"]} />
            <BoxSection layout={9} data={content["market-works-body"]} />
            <BoxSection layout={11} data={content["market-localize-body"]} />
            <IntroAreaReverse layout={3} data={content["translation-why-andovar"]} />
            <CaseStudy layout={3} data={caseStudiesData} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};
export const query = graphql`
  query MarketResearchTranslationServicesPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "market-translation" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;

MarketResearchTranslationServicesPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default MarketResearchTranslationServicesPage;
